/* eslint-disable react/no-danger */
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/app-layout";
import SEO from "../components/seo";
import { Typography } from "../constants/typography";
import { Constants } from "../constants";
import { bindOsanoDrawer } from "../../static/js/legal_osano_binder";

const LegalPage = ({ data }) => {
  const { title, legal, databaseId } = data.legalPage;

  useEffect(() => {
    if (databaseId === 508) {
      // do not sell or share my personal information
      bindOsanoDrawer(".osano_initd");
    }
  }, []);

  return (
    <Layout defaultHeaderColor="text-white">
      <SEO title={title} />
      <div
        id="legal-page"
        className={`${Typography.body1} bg-black text-white light-header header-section`}
        data-scroll-section
      >
        <div className={Constants.simplePageWrapperClasses}>
          <h1 className={`${Typography.h2} mb-10`}>{title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: legal.body }}
            className={`${Typography.body1} legal__body legal_${databaseId}`}
          />
        </div>
      </div>
    </Layout>
  );
};

export default LegalPage;

export const legalData = graphql`
  query LegalPageQuery($id: Int) {
    legalPage: wpLegalPage(databaseId: { eq: $id }) {
      title
      databaseId
      legal {
        body
      }
    }
  }
`;

export const bindOsanoDrawer = (location) => {
  const osanoLinkElement = Array.from(document.querySelectorAll(location));

  osanoLinkElement.forEach((el) => {
    el.setAttribute("style", "cursor: pointer; text-decoration: underline;");
    el.addEventListener("click", () => {
      // eslint-disable-next-line no-undef
      Osano.cm.showDrawer("osano-cm-dom-info-dialog-open");
    });
  });
};
